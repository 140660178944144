<template>
  <div class="no-padding">
    <b-select v-model="computedValue" :placeholder="fieldTitle">
      <option v-if="computedValue" class="nofilter" value>{{ fieldTitle }}</option>
      <option v-for="option in options" :value="option.id" :key="option.id">{{ option.nama }}</option>
    </b-select>
  </div>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";

export default {
  name: "GenericFilterSelect",
  props: {
    apiPath: String,
    field: String,
    value: [String, Number]
  },
  data() {
    return {
      valueData: this.value,
      fieldTitle: this.field[0].toUpperCase() + this.field.slice(1),
      options: []
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.valueData;
      },
      set(value) {
        this.valueData = value;
        const realValue = value === "" ? null : value;
        this.$emit("input", realValue);
      }
    }
  },
  methods: {
    fetchData() {
      const apiURL = `${APP_CONFIG.baseAPIURL}${this.apiPath}`;
      axios
        .get(apiURL)
        .then(response => {
          this.options = response.data;
        })
        .catch(() => {
          this.options = [];
        });
    }
  },
  watch: {
    // When v-model is changed: update internal value
    value(value) {
      this.valueData = value;
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 480px) {
  .filter2 {
    flex: none;
    width: 6rem;
    margin-bottom: 0.75rem;
  }

  .filter3 {
    flex: none;
    width: 5rem;
    margin-bottom: 0.75rem;
  }
}

.no-padding:not(:first-child) {
  margin-left: 5px;
}

::v-deep option.nofilter {
  color: #999;
}
</style>
